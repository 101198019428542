<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <p class="text-h6 font-museo-sans mb-0 secondary--text">
          Emisión de CDP
        </p>
        <p class="text-body-2 font-museo-sans primary--text">
          Ejercicio {{ anioFiscal }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div class="d-flex justify-end">
            <v-btn dark >

            </v-btn>
        </div>
         </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "EmisionCDPView",
  data: () => ({}),
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
  },
};
</script>
